import React from "react"
import SEO from "../components/seo"
import LayOut from "../components/layout"
import Container from '@material-ui/core/Container';
import styled from "styled-components"






export default () => {

return(


  <LayOut
  background="/img_1.jpg"
  >
     <SEO
       lang="ar"
       title="ahmed elkordy"
       description="dev by ahmed elkordy"
       meta="xxx xxx xxx xxx"
       />

       <Container fixed style={{padding: "60px 43px"}}>


        <SBlock>  للوالدين اهمية كبرى في حياة الطفل ، فهما المحيط الاول الذي يكسبه الخبرات ويحد شخصيته وتطلعاته المستقبلية .ولذا يعتبران الوالدان حجر الزاوية في عملية التنشئة الاجتماعية من حيث كونهما أنموذجا يتعلم من خلاله الأبناء ويتأثرون بهم ويؤثر فيهم ، كما أن آثار التنشئة في سن ما قبل المدرسة تبقى وتتأصل خلال الحياة المدرسية ، ومن ثم فان العناية بالتكوين النفسي مع اتاحة الفرصة له للنمو والتواصل والتفاعل مع افراد الأسرة في مواقف عادية تصقله وتساعده على نمو شخصيته .  </SBlock>


        <STittle>   الدور التفاعلي للأم يتمثل بمايلي : </STittle>

        <SBlock> تلعب الأم الدور المهم في عملية تنشئة طفلهما فأول غذاء يحصل عليه الطفل من الأم وهي التي تسهر على رعايته وحمايته والاعتناء به ، وتوفير المأكل الصحي والملبس النظيف والنوم الهاديء . </SBlock>


        <SBlock>   يعتمد عليها كليا لأشباع حاجاته العضوية والنفسية سواء في السنوات الاولى لميلاده او في السنوات اللاحقة من مرحلة الطفولة . </SBlock>


        <SBlock> يقع على عاتقها رعاية شؤونه منذ ولادته وتولي إشباع حاجاته ، ولذا تلعب الأم دور المساندة العاطفية وتمثل مصدر للاشباع المادي والنفسي لديه .   </SBlock>







      <STittle>   الدور التفاعلي للأب يتمثل بما يلي : </STittle>



      <SBlock>  مشاركة الأب في الأسرة يساعد على ترك الاعتمادية الكاملة على الام وينظر الى الأب على انه ممثل للعالم الخارجي ، ومصدر لتوسيع افاق الطفل ونقل الشعور بالنظام الاجتماعي . </SBlock>



       <SBlock>  كما انه مصدر السلطة بالكامل في المنزل وهو الذي يصدر الأوامر ويفرض الثواب والعقاب فالطفل إذا يقلد أباه ويتقمص شخصيته وينظر إليه كقدوة حسنة ومثال طيب . ولذا تعتبر شخصية الأب عاملا مهما ومؤثرا في شخصية الأبناء ، فالشخصية السوية للأب تنتج بالطبع أبناء اسوياء لا يعانون من اية توترات او انفعالات سيئة قد تجعل الطفل مضطربا السلوك والعكس صحيح فالاب القاسي المتشدد يكون قدوة غير مرغوب فيها لدى الأبناء
       - الاسرة كشبكة علاقات انسانية اجتماعية </SBlock>




       <SBlock>
       رسم الابتسامة عند التعامل مع ذوي الاحتياجات الخاصة.تجنّب التحديق أو إظهار أي ردّ فعل عند رؤية أي شخص من ذوي الاحتياجات الخاصة، وذلك لتجنّب إحراجه.سؤال ذوي الاحتياجات الخاصة في حال حاجتهم إلى المساعدة، وتجنّب أخذ زمام المُبادرة دون عِلمهم، لئلا يشعروا بالشفقة.التحدث مع ذوي الاحتياجات الخاصة بشكل إيجابيّ، والابتعاد تماماً عن التذمر أو التحدث عن المشاكل الخاصة.في حال كان الشخص من ذوي الإعاقات السمعيّة، فيجب التربيت على كتفه من أجل لفت انتباهه، ثمّ التحدث معه بتمهّلٍ وبشكل واضح.في حال كان الشخص من المُقعَدين، فيجب الجلوس عند التحدث معه، وذلك ليكون المُتحدِث على مستوى الشخص المُقعَد.في حال كان الشخص من ذوي الإعاقاتالبصرية، فمن الأفضل لمس يده ليعرف أنّ هناك من يتحدّث معه، ويُفضَّل وصف المكان الذي يتمّ التواجد فيه، مع ذكر أسماء الأشخاص الموجودين هناك.
       </SBlock>



       <SBlock>
       عدم الخوف من الأطفال ذوي الاحتياجات الخاصة؛ لأنّ وجود إعاقة ما لا يعني أنّ الأمر مُخيف.التعامل معهم بطريقة طبيعيّة؛ وذلك لأنّ بعض الأطفال والمراهقين يشكون أنّ لديهم شعوراً غير واثق تجاه إعاقتهم، لذا يجب التعامل معهم بشكل هادئ وطبيعي.التكلّم مع الأطفال ذوي الاحتياجات الخاصة بذات الطريقة التي يتمّ بها التحدث مع أي طفل طبيعي آخر، دون استخدام أيّ تعبيرات طفولية أو نبرة صوت غير مناسبة معهم.التعرّف على نقاط القوة لديهم، ثمّ تشجيعهم على إظهار مواهبهم، والتعامل معهم بذات الطريقة التي يتم التعامل بها مع أندادهم الطبيعيّين.إتاحة المجال أمامهم من أجل مساندة من حولهم من الأشخاص؛ سواءً كان ذلك باحتضان إنسان محتاج للعاطفة، أو تقديم المساعدة في حال احتاج أحدهم مساعدةً في حلّ الواجبات المدرسيّة.النظر إليهم بالطريقة التي يودّون من الآخرين رؤيتهم بها، مع مراعاة احتياجاتهم والاستماع لهم.التعرّف على ماهية الإعاقة المُصاب بها الطفل، والتحدث مع أهل ذوي الاحتياجات الخاصة للحصول على معلومات لتحديد احتياجاته بشكل دقيق.
       احترام اختلاف الطفل عن الآخرين، مع تعليمه ألا يخجل من الأدوات التي يستخدمها، وألا يحاول الظهور بشكل طبيعي كباقي رفاقه، بل عليه تقبّل حاله وإعاقته، كما يجب تعليم من حوله بأن يقبّلوا اختلافه عنهم.
       دعوة الطفل للتعرف على أطفال آخرين ذوي احتياجات خاصة، وتعريفه على قصصهم، ممّا يُساهم في إشعاره بأنّ الحياة الجيدة ممكنة مع وجود إعاقة.تقبّل الطفل كما هو، وتعليمه بأنّ الكرامة الإنسانية لا تتأثّر بوجود الإعاقات لدى الإنسان.
       </SBlock>



       </Container>
  </LayOut>
)
}




const STittle = styled.h1`
    color: #F44336;
`




const SBlock = styled.p`
    font-size: 20px;
    margin-bottom: 40px;
`
